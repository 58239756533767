import PropTypes from "prop-types";

interface WeployeeInformationProps {
  skills: string;
  location: string;
  covidVaccination: string;
  visaStatus: string;
}

export const WeployeeInformation = ({
  skills,
  location,
  covidVaccination,
  visaStatus,
}: WeployeeInformationProps): JSX.Element => {
  const data = [
    ["Skills", skills],
    ["Location", location],
    ["COVID Vaccination", covidVaccination],
    ["Residency", visaStatus],
  ];

  return (
    <div className='w-full'>
      <div className='max-w-xxl'>
        {data.map(([key, value]) => (
          <div
            key={key}
            className='flex text-legacyColor-neutral-1-500 border-b border-legacyColor-neutral-2-500 pb-2 mb-2'
          >
            <div className='min-w-[145px] font-bold'>{key}</div>
            <div>{value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

WeployeeInformation.propTypes = {
  skills: PropTypes.string,
  location: PropTypes.string,
  covidVaccination: PropTypes.string,
  visaStatus: PropTypes.string,
};
