import clsx from "clsx";

import { ButtonType } from "./types";

interface SecondaryButtonProps {
  disabled?: boolean;
  fullWidth?: boolean;
  label: string;
  size?: ButtonSize;
  type?: ButtonType;
  onClick?: () => void;
}

type ButtonSize = "SMALL" | "MEDIUM" | "LARGE";

const SIZE_MAPS: Record<ButtonSize, string> = {
  SMALL: "btn-sm",
  MEDIUM: "btn-md",
  LARGE: "btn-lg",
};

export const SecondaryButton = ({
  disabled,
  fullWidth = false,
  label,
  size = "MEDIUM",
  type = "button",
  onClick = () => undefined,
}: SecondaryButtonProps): JSX.Element => {
  return (
    <button
      className={clsx("btn-secondary", SIZE_MAPS[size], fullWidth && "w-full")}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {label}
    </button>
  );
};
