import i18next from "i18next";
import "@/i18n/config";
import { useCallback } from "react";
import SVG from "react-inlinesvg";

import Wage from "@assets/illustrations/wave.svg";

declare const window: any;

export const EmptyStage = ({ jobOpeningDetailEmptyStageImageURL }) => {
  const customStyles = {
    height: "200px",
    width: "100%",
  };
  const renderImage = useCallback(() => {
    if (jobOpeningDetailEmptyStageImageURL) {
      return (
        <img
          src={jobOpeningDetailEmptyStageImageURL}
          alt='Empty Stage Image'
          style={customStyles}
        />
      );
    } else {
      return <SVG className='w-full' src={Wage} />;
    }
  }, [jobOpeningDetailEmptyStageImageURL]);

  return (
    <>
      <div className='flex flex-col justify-center items-center'>
        {renderImage()}
        <h4 className='font-bold text-black'>No updates yet</h4>
        <p className='my-4 font-light text-legacyColor-neutral-1-900'>
          {i18next.t("clientApp.weployeeAreCurrently", {
            terminologyEmployee: window.terminologyEmployee,
          })}
          <br />
          reviewing your job post
        </p>

        <a
          href='#'
          className='text-legacyColor-neutral-1-900 font-bold underline'
        >
          Refresh
        </a>
      </div>
    </>
  );
};
