import { useForm, useWatch } from "react-hook-form";
import SVG from "react-inlinesvg";

import subtractIcon from "@assets/icons/subtract.svg";

import { MessageBubbleComponent } from "./MessageBubbleComponent";

import { sendMessage } from "@/client_app/components/jobOpeningDetails/services/MessageService";
import { flash } from "@/ui/Flash";

export interface MessageInterface {
  id: string;
  conversationId: string;
  messageableType: string;
  messageableId: string;
  fromParticipantId: string;
  toParticipantId: string;
  senderName: string;
  senderClassName: string;
  content: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

interface MessageProps {
  jobOpeningCandidateId: string;
  currentUserClassName: string;
  weployeeStatus: string;
  messages: Array<MessageInterface>;
  fetchProfileData: () => void;
}

export const Message = ({
  jobOpeningCandidateId,
  currentUserClassName,
  messages,
  fetchProfileData,
  weployeeStatus,
}: MessageProps): JSX.Element => {
  const { register, control, handleSubmit, reset } = useForm({
    defaultValues: {
      content: "",
    },
  });

  const content = useWatch({
    control,
    name: "content",
  });

  const onSubmit = () => {
    if (typeof content === "string" && content.trim().length === 0) {
      flash.alert("MESSAGE IS REQUIRED!");
    } else {
      sendMessage(jobOpeningCandidateId, content).then((response) => {
        if (response.ok) {
          flash.notice("MESSAGE SENT SUCCESSFULLY!");
          fetchProfileData();
          reset();
        } else {
          flash.alert("COULD NOT SEND YOUR MESSAGE, PLEASE TRY AGAIN LATER");
        }
      });
    }
  };

  const renderMessages = () => (
    <div className='flex flex-col max-h-[425px]'>
      <div className='overflow-y-auto flex flex-col-reverse'>
        <div className='messages'>
          {messages &&
            messages.length > 0 &&
            messages.map((message, index) => (
              <MessageBubbleComponent
                key={index}
                currentUserClassName={currentUserClassName}
                senderName={message.senderName}
                senderClassName={message.senderClassName}
                content={message.content}
                createdAt={message.createdAt}
              />
            ))}
        </div>
      </div>
    </div>
  );

  const renderMessageForm = () => (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex items-center gap-3 mb-2 mt-4'>
          <div className='w-full'>
            <textarea
              className='form-control rounded-3xl w-full'
              placeholder='Type your message...'
              {...register("content")}
            ></textarea>
          </div>

          <button
            className='bg-primary-100 w-16 h-14 rounded-[50%]'
            type='submit'
          >
            <SVG
              className='m-auto icon icon-sm'
              width={16}
              height={16}
              src={subtractIcon}
            />
          </button>
        </div>
      </form>
      {renderMessageFooter()}
    </>
  );

  const reFetchProfileData = () => {
    fetchProfileData();
    flash.notice("MESSAGES ARE UP TO DATE");
  };

  const renderMessageFooter = () => (
    <div className='text-overline text-legacyColor-neutral-1-300'>
      Click{" "}
      <button className='underline' onClick={() => reFetchProfileData()}>
        here
      </button>{" "}
      to see the lastest responses
    </div>
  );

  return (
    <>
      <h5 className='font-bold mb-6'>Messaging</h5>
      {renderMessages()}
      {weployeeStatus != "deactivated" && renderMessageForm()}
    </>
  );
};
