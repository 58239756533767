import { gaClickTrack } from "helpers/ga_event_tracking";
import { tenantPath } from "helpers/tenant_path";
import i18next from "i18next";
import "@/i18n/config";
import PropTypes from "prop-types";
import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import SVG from "react-inlinesvg";

import faceIcon from "@assets/icons/face.svg";
import messageIcon from "@assets/icons/message.svg";
import phoneIcon from "@assets/icons/phone.svg";

import { SEND_JOB_OPENING_REQUEST_HELP_PATH } from "@/client_app/config";

import { RequestHelpModal } from "./RequestHelpModal";

import { PrimaryButton } from "@/ui/buttons";

const RequestHelp = ({
  terminologyOrganization,
  jobOpeningId,
  salespersonName,
  salespersonPhoneNumber,
  salespersonEmail,
  talentDepartmentContactEmail,
  financeDepartmentContactEmail,
}): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      requestHelpContent: "",
    },
  });

  const requestHelpContent = useWatch({
    control,
    name: "requestHelpContent",
  });

  const onSubmit = (data) => {
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    fetch(tenantPath(SEND_JOB_OPENING_REQUEST_HELP_PATH), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        job_opening_id: jobOpeningId,
        request_help_content: data.requestHelpContent,
      }),
    }).then((response) => {
      if (response.ok) {
        setIsSuccessModal(true);
        setOpenModal(true);
        reset();
      } else {
        setIsSuccessModal(false);
        setOpenModal(true);
      }
    });
    gaEventTracking("SEND JOB OPENING REQUEST HELP");
  };

  const gaEventTracking = (text: string) => {
    gaClickTrack({
      click_text: text,
    });
  };

  const renderSpeakToSales = () => {
    return (
      salespersonEmail &&
      salespersonName && (
        <div className='py-2 border-t border-neutral-200 last:border-b'>
          <p className='my-2 font-semibold'>Speak to Sales</p>
          <div className='my-2 flex gap-2 items-center'>
            <SVG className='w-4 h-4' src={faceIcon} />
            <span>{salespersonName}</span>
          </div>
          {salespersonPhoneNumber && (
            <div className='my-2 flex gap-2 items-center'>
              <SVG className='w-4 h-4' src={phoneIcon} />
              <span className='underline decoration-1 decoration-neutral-200'>
                {salespersonPhoneNumber}
              </span>
            </div>
          )}
          <div className='my-2 flex gap-2 items-center'>
            <SVG className='w-4 h-4' src={messageIcon} />
            <span className='underline decoration-1 decoration-neutral-200'>
              {salespersonEmail}
            </span>
          </div>
        </div>
      )
    );
  };

  const renderSpeakToTalent = () => {
    return (
      <div className='py-2 border-t border-neutral-200 last:border-b'>
        <p className='my-2 font-semibold'>Speak to Talent</p>
        <div className='my-2 flex gap-2 items-center'>
          <SVG className='w-4 h-4' src={messageIcon} />
          <span className='underline decoration-1 decoration-neutral-200'>
            {talentDepartmentContactEmail}
          </span>
        </div>
      </div>
    );
  };

  const renderSpeakToFinance = () => {
    return (
      <div className='py-2 border-t border-neutral-200 last:border-b'>
        <p className='my-2 font-semibold'>Speak to Finance</p>
        <div className='my-2 flex gap-2 items-center'>
          <SVG className='w-4 h-4' src={messageIcon} />
          <span className='underline decoration-1 decoration-neutral-200'>
            {financeDepartmentContactEmail}
          </span>
        </div>
      </div>
    );
  };

  const title = i18next.t(
    `clientApp.requestHelpTitle.${terminologyOrganization.toLowerCase()}`
  );

  const description = i18next.t(
    `clientApp.requestHelpDescription.${terminologyOrganization.toLowerCase()}`
  );

  const render_default_request_help = () => {
    return (
      <>
        <RequestHelpModal
          isOpen={openModal}
          isSuccess={isSuccessModal}
          closeModal={() => setOpenModal(false)}
        />

        <div className='border-neutral-500 border p-6 pt-8 rounded-lg max-w-sm text-shade-100'>
          <h5 className='font-bold mb-4'>{title}</h5>
          <p className='mb-4'>{description}</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='mb-4'>
              <input
                className='form-control rounded-md'
                placeholder='How can we help?'
                type='text'
                {...register("requestHelpContent", {
                  required: true,
                })}
              />
              {errors.requestHelpContent && (
                <span className='text-legacyColor-semantic-warning-200 text-sm'>
                  This field is required
                </span>
              )}
            </div>

            <div className='mb-4'>
              <PrimaryButton
                type='submit'
                label='SEND MESSAGE'
                disabled={
                  typeof requestHelpContent === "string" &&
                  requestHelpContent.trim().length === 0
                }
              />
            </div>

            {renderSpeakToSales()}
            {talentDepartmentContactEmail && renderSpeakToTalent()}
            {financeDepartmentContactEmail && renderSpeakToFinance()}
          </form>
        </div>
      </>
    );
  };

  const render_monash_request_help = () => {
    return (
      <div className='border-neutral-500 border p-6 pt-8 rounded-lg max-w-sm text-shade-100'>
        <h5 className='font-bold mb-4'>{title}</h5>
        <p className='mb-4'>{description}</p>
        <div className='my-2 flex gap-2 items-center'>
          <SVG className='w-4 h-4' src={messageIcon} />
          <span className='underline decoration-1 decoration-neutral-200'>
            graduates@monashtalent.com.au
          </span>
        </div>
      </div>
    );
  };

  if (String(terminologyOrganization).toLowerCase() == "monash") {
    return render_monash_request_help();
  } else {
    return render_default_request_help();
  }
};

RequestHelp.propTypes = {
  jobOpeningId: PropTypes.string,
};

export { RequestHelp };
