import { pickBy } from "lodash";
import PropTypes from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const HiddenFields = [
  "faculty",
  "weightedAverageMark",
  "emergencyContact",
  "emergencyPhone",
  "contactNumberPrimary",
  "emailAddressPrimary",
];

const translationMapping = {
  generalInformation: "General Information",
  academicRecord: "Academic Record",
  professionalDetails: "Professional Details",
  studentId: "Student ID",
  firstName: "First Name",
  surname: "Surname",
  preferredName: "Preferred Name",
  address: "Address",
  emergencyContact: "Emergency Contact",
  emergencyPhone: "Emergency Phone",
  workRights: "Work Rights",
  noticePeriod: "Notice Period",
  faculty: "Faculty",
  courseTitle: "Course Title",
  courseEnrolledSubjectsCompleted: "Course(s) Enrolled - Subjects Completed",
  courseEnrolledSubjectsEnrolledIn: "Course(s) Enrolled - Subjects Enrolled In",
  expectedCourseCompletion: "Expected Course Completion",
  weightedAverageMark: "Weighted Average Mark",
  wam: "Overall Weighted Average Mark",
  work: "Work Experience",
  project: "Project Experience",
  volunteering: "Volunteering Experience",
  studentClubs: "Student Clubs",
  linkedin: "Linkedin",
};

interface StudentGeneralInformationProps {
  studentId: string;
  firstName: string;
  surname: string;
  preferredName: string;
  emergencyContact: string;
  emergencyPhone: string;
  aboriginalOrTorresStraitIslandOrigin: string;
  workworkRights_rights: string;
  noticePeriod: string;
}

interface WorkExperienceProps {
  experienceType: string;
  title: string;
  organizationName: string;
  startAndEndDate: string;
  summary: string;
}

interface StudentClubProps {
  tite: string;
  startAndEndDate: string;
  summary: string;
}
interface GroupedWorkingExperiencesProps {
  work: Array<WorkExperienceProps>;
  project: Array<WorkExperienceProps>;
  volunteering: Array<WorkExperienceProps>;
}
interface StudentAcademicRecordProps {
  faculty: string;
  courseTitle: string;
  courseEnrolledSubjectsCompleted: string;
  courseEnrolledSubjectsEnrolledIn: string;
  expectedCourseCompletion: string;
  weightedAverageMark: string;
}

interface StudentProfessionalDetailsProps {
  groupedWorkingExperiences?: GroupedWorkingExperiencesProps;
  studentClubs?: Array<StudentClubProps>;
  linkedin?: string;
}

interface StudentProfileContentProps {
  candidateProfile?: {
    generalInformation?: StudentGeneralInformationProps;
    academicRecord?: Array<StudentAcademicRecordProps>;
    professionalDetails?: StudentProfessionalDetailsProps;
  };
  wam?: string;
}

export const StudentProfileContent = ({
  candidateProfile,
  wam,
}: StudentProfileContentProps): JSX.Element => {
  const TAB_CLASSES = "focus:outline-none cursor-pointer mr-8 text-base pb-2";
  const TAB_ACTIVE_CLASSES =
    "text-legacyColor-neutral-1-900 border-primary-900 font-bold h-full border-b-2";

  const { generalInformation, academicRecord, professionalDetails } =
    candidateProfile || {};

  const renderInformationByScope = (scope) => {
    const hasValueScope = pickBy(scope, (value, key) => {
      return value && value.length && !HiddenFields.includes(key);
    });

    const keys = Object.keys(hasValueScope);
    return (
      <div>
        {keys.map((key) => {
          return (
            <div
              key={key}
              className='flex text-base border-t last:border-b border-legacyColor-neutral-2-500'
            >
              <div className='text-legacyColor-neutral-1-900 py-3 font-semibold break-words min-w-[35%] max-w-[35%]'>
                {translationMapping[key]}
              </div>
              <div className='p-3 break-words max-w-[65%]'>
                {hasValueScope[key]}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderGeneralInformationItem = () => {
    return renderInformationByScope(generalInformation);
  };

  const renderAcademicRecordInformation = () => {
    return (
      <>
        {wam && (
          <div className='flex text-base border-t border-b border-legacyColor-neutral-2-500'>
            <div className='text-legacyColor-neutral-1-900 py-3 font-semibold break-words min-w-[35%] max-w-[35%]'>
              {translationMapping["wam"]}
            </div>
            <div className='p-3 break-words max-w-[65%]'>{wam}%</div>
          </div>
        )}
        {academicRecord.map((academicRecordItem, indx) => {
          return (
            <div key={indx} className='mt-12'>
              {renderInformationByScope(academicRecordItem)}
            </div>
          );
        })}
      </>
    );
  };

  const renderWorkExperiences = (groupedWorkingExperiences) => {
    const hasValueScope = pickBy(groupedWorkingExperiences, (value, key) => {
      return value && value.length && !HiddenFields.includes(key);
    });
    const keys = Object.keys(hasValueScope);
    return (
      <div>
        {keys.map((key) => {
          return (
            <div key={key}>
              <div className='text-lg font-bold break-words mb-6'>
                {translationMapping[key]}
              </div>
              {hasValueScope[key].map((item, indx) => {
                return (
                  <div key={indx} className='text-base break-words mb-6'>
                    <div className='font-semibold'>{item.title}</div>
                    <div className='py-1'>{item.organizationName}</div>
                    <div>{item.startAndEndDate}</div>
                    <div className='whitespace-pre-line pt-3'>
                      {item.summary}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const renderStudentClubs = (studentClubs) => {
    return (
      <div>
        <div className='text-lg font-bold break-words mb-6'>
          {translationMapping["studentClubs"]}
        </div>
        {studentClubs.map((item, indx) => {
          return (
            <div key={indx} className='text-base break-words mb-6'>
              <div className='font-semibold'>{item.title}</div>
              <div>{item.startAndEndDate}</div>
              <div className='whitespace-pre-line pt-3'>{item.summary}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderProfessionalDetailsTab = () => {
    const { groupedWorkingExperiences, studentClubs, linkedin } =
      professionalDetails;

    return (
      <div>
        {renderWorkExperiences(groupedWorkingExperiences)}
        {!!studentClubs.length && renderStudentClubs(studentClubs)}
        {linkedin && linkedin.length && (
          <div className='break-words text-base mb-6'>
            <div className='text-lg font-bold pb-3'>
              {translationMapping["linkedin"]}
            </div>
            <a href={linkedin} target='_blank' className='underline'>
              {linkedin}
            </a>
          </div>
        )}
      </div>
    );
  };

  return (
    <Tabs>
      <TabList className='flex py-8'>
        <Tab className={TAB_CLASSES} selectedClassName={TAB_ACTIVE_CLASSES}>
          {translationMapping["generalInformation"]}
        </Tab>
        <Tab className={TAB_CLASSES} selectedClassName={TAB_ACTIVE_CLASSES}>
          {translationMapping["academicRecord"]}
        </Tab>
        <Tab className={TAB_CLASSES} selectedClassName={TAB_ACTIVE_CLASSES}>
          {translationMapping["professionalDetails"]}
        </Tab>
      </TabList>
      <TabPanel>
        <div className='mt-4'>{renderGeneralInformationItem()}</div>
      </TabPanel>
      <TabPanel>
        <div className='mt-4'>{renderAcademicRecordInformation()}</div>
      </TabPanel>
      <TabPanel>
        <div className='mt-4'>{renderProfessionalDetailsTab()}</div>
      </TabPanel>
    </Tabs>
  );
};

StudentProfileContent.propTypes = {
  candidateProfile: PropTypes.any,
  wam: PropTypes.any,
};
