export const tenantPath = (url: string, withEmployees = false): string => {
  const subdomain = window.location.hostname.split(".");
  if (subdomain.includes("monash")) {
    let tenantUrl = url.replace("weployer", "employer");
    if (withEmployees) {
      tenantUrl = tenantUrl.replace("weployees", "students");
    }
    return tenantUrl;
  } else {
    return url;
  }
};
