import styled from "styled-components";

import { grid } from "@/client_app/components/jobOpeningDetails/styles/constants";

// $ExpectError - not sure why
export const Title = styled.div`
  padding: ${grid}px;
  transition: background-color ease 0.2s;
  flex-grow: 1;
  user-select: none;
  position: relative;
  font-weight: bold;
  &:focus {
    outline: 2px solid #998dd9;
    outline-offset: 2px;
  }
`;
