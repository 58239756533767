import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  candidateName: "",
  jobOpeningCandidateId: "",
  stage: "",
  columns: null,
  currentStage: "",
  nextStage: "",
  remoteWorkingLocation: "",
};

export const ModalSlice = createSlice({
  name: "offerModal",
  initialState,
  reducers: {
    open: (state, action) => {
      return {
        ...state,
        isOpen: action.payload.isOpen,
        candidateName: action.payload.candidateName,
        jobOpeningCandidateId: action.payload.jobOpeningCandidateId,
        stage: action.payload.stage,
        columns: action.payload.columns,
        currentStage: action.payload.currentStage,
        nextStage: action.payload.nextStage,
        remoteWorkingLocation: action.payload.remoteWorkingLocation,
      };
    },
    close: (state, action) => {
      return {
        ...state,
        isOpen: action.payload.isOpen,
        candidateName: "",
        jobOpeningCandidateId: "",
        stage: "",
        columns: null,
        currentStage: action.payload.currentStage,
        nextStage: action.payload.nextStage,
      };
    },
  },
});

export const { open, close } = ModalSlice.actions;
const OfferJobOpeningCandidateModalReducer = ModalSlice.reducer;
export { OfferJobOpeningCandidateModalReducer };
