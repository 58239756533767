export const ns1 = {
  clientApp: {
    weployeeLocation: "{{terminologyEmployee}} LOCATION",
    readyToHireYourWeployee:
      "Ready to hire your {{terminologyEmployee}}? Send an offer for this position",
    weployeeAreCurrently: "{{terminologyEmployee}}s are currently",
    noAttendancesMessage:
      "Your {{terminologyEmployee}}s timesheets will appear here",
    yourWeployees: "Your {{terminologyEmployee}}s",
    approveMultipleWeployeesTimesheet:
      "Approve Multiple {{terminologyEmployee}}'s Timesheet",
    weployeeNotifiedOfRequestedChange:
      "{{terminologyEmployee}} notified of requested changes",
    pleaseEnsureCheckedNotesOnYourAttendances:
      "*Please ensure you have checked the additonal notes added to your {{terminologyEmployee}}s attendances.",
    weployeesAcceptOrDeclineExtensionRequest:
      "{{terminologyEmployee}}s can either accept or decline an extension request. We'll notify you by email.",
    buyoutRequestFee:
      "A buyout fee will be calculated. Our team will touch base with you regarding next steps",
    yourWeployeesHaveSentExtensionRequestsNote:
      "Your {{terminologyEmployee}}s have been sent their extension requests.<br/ >{{terminologyEmployee}}s can either accept or decline the extension,<br />we will let you know via email when they do.",
    noActivePositionsMessage:
      "Your active {{terminologyEmployee}}s will appear here",
    cannotSubmitRequest: "Cannot submit request",
    requestHelpTitle: {
      weploy: "Request Help From Weploy",
      monash: "Get in touch",
    },
    requestHelpDescription: {
      weploy: "Speak to a Weploy team member",
      monash:
        "We are here to help! Contact us and we will respond to you within 1 business day.",
    },
    yourWeployeesHaveSentBuyoutRequestsNote:
      "You are one step closer to permanently placing your </br>{{terminologyEmployee}}(s) in your company. Our team will be in touch with </br> you shortly to discuss the buyout fee and next steps.",
    positionTabs: {
      current: "Current",
      past: "Past",
    },
    pastPositions: {
      button: {
        requestRehire: "Request Rehire",
      },
      tableHeader: {
        role: "ROLE",
        startDate: "START DATE",
        endDate: "END DATE",
      },
    },
    noPastPositions: {
      searchResults: {
        heading: "No matching positions found",
        textContent: "Try searching for someone different",
      },
      defaultResults: {
        heading: "No past positions",
        textContent: "Your past {{terminologyEmployee}}s will appear here",
      },
    },
    rehireRequestPreview: {
      title: "Rehire Requests",
      successNotice: "Requested successfully!",
      form: {
        employmentType: {
          label: "Employment Type",
        },
        startDate: {
          label: "Select Ideal Start Date",
          placeholder: "Select Start Date",
        },
        endDate: {
          label: "Select End Date",
          placeholder: "Select End Date",
        },
        salary: {
          label: "Salary",
          placeholder: "Input a salary",
        },
        additionalComments: {
          label: "Additional Comments",
          placeholder: "Input additional comments",
        },
        infoMessage:
          "The {{terminologyEmployee}} will be notified of the rehire opportunity. Our team will touch base with you regarding next steps",
        submitButton: "Request Rehire",
      },
    },
  },
  organizationApp: {
    startTypingWeployees: "Start typing {{terminologyEmployee}}s name here...",
  },
} as const;
