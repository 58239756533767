import { createSlice } from "@reduxjs/toolkit";
type SliceState = { columns: [] };

const initialState: SliceState = { columns: null };

export const BoardSlice = createSlice({
  name: "board",
  initialState,
  reducers: {
    update: (state, action) => {
      return {
        ...state,
        columns: action.payload.columns,
      };
    },
  },
});

export const { update } = BoardSlice.actions;

const BoardReducer = BoardSlice.reducer;

export { BoardReducer };
