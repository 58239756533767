import { tenantPath } from "helpers/tenant_path";
import { JOB_OPENING_CANDIDATE_PATH } from "packs/client_app/config";
import { getHeaders } from "packs/client_app/config/request_headers";

export const getJobOpeningCandidate = (jobOpeningCandidateId) => {
  return fetch(tenantPath(JOB_OPENING_CANDIDATE_PATH(jobOpeningCandidateId)), {
    method: "GET",
    headers: getHeaders(),
  });
};

export const updateJobOpeningCandidateStage = (
  jobOpeningCandidateId,
  stage
) => {
  return fetch(tenantPath(JOB_OPENING_CANDIDATE_PATH(jobOpeningCandidateId)), {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify({
      stage: stage,
    }),
  });
};
