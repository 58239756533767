import { tenantPath } from "helpers/tenant_path";
import {
  WEPLOYEES_JOB_OPENING_CANDIDATE_SEND_MESSAGE_PATH,
  WEPLOYEES_JOB_OPENING_CANDIDATE_MESSAGES_BULK_SEENS_PATH,
} from "packs/client_app/config";
import { getHeaders } from "packs/client_app/config/request_headers";

export const sendMessage = (jobOpeningCandidateId, content) => {
  return fetch(
    tenantPath(
      WEPLOYEES_JOB_OPENING_CANDIDATE_SEND_MESSAGE_PATH(jobOpeningCandidateId)
    ),
    {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        content: content,
      }),
    }
  );
};

export const updateUnreadMessages = (
  jobOpeningCandidateId,
  unreadMessageIds
) => {
  return fetch(
    tenantPath(
      WEPLOYEES_JOB_OPENING_CANDIDATE_MESSAGES_BULK_SEENS_PATH(
        jobOpeningCandidateId
      )
    ),
    {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        unread_message_ids: unreadMessageIds,
      }),
    }
  );
};
