import { toast, Toast } from "react-hot-toast";
import SVG from "react-inlinesvg";

import crossURL from "@assets/icons/cross.svg";

const Flash = (message: string, className: string, t: Toast): JSX.Element => {
  return (
    <div className={`${className} ${t.visible ? "flash-active" : ""}`}>
      <p>{message}</p>
      <SVG
        src={crossURL}
        className='icon-sm fill-current ml-2 cursor-pointer'
        onClick={() => {
          toast.dismiss();
        }}
      />
    </div>
  );
};

const notice = (message: string): void => {
  const className = "flash";

  toast.custom((t) => Flash(message, className, t));
};

const alert = (message: string): void => {
  const className = "flash flash-alert flash-shake";

  toast.custom((t) => Flash(message, className, t));
};

const flash = {
  notice,
  alert,
};

export { flash };
