import Avatar from "react-avatar";
import Modal from "react-modal";

Modal.setAppElement("body");

export const PipelineStatusConfirmaModal = ({
  selectedPipelineStatus,
  setSelectedPipelineStatus,
  profileData,
  updatePipelineStatus,
}): JSX.Element => {
  const customStyles = {
    overlay: {
      zIndex: 9998,
      opacity: 1,
    },
    content: {
      background: "none",
      border: "none",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      padding: "0",
    },
  };
  const { pipelineStatus, avatarName } = profileData;
  const { selectedPipelineStatusValue, selectedPipelineStatusName } =
    selectedPipelineStatus;

  const onConfirm = () => {
    updatePipelineStatus(selectedPipelineStatusValue);
  };

  return (
    <Modal
      isOpen={
        typeof selectedPipelineStatusValue === "string" &&
        selectedPipelineStatusValue.trim().length > 0 &&
        !(selectedPipelineStatusValue === pipelineStatus) &&
        selectedPipelineStatusValue == "rejected_by_client"
      }
      contentLabel='Example Modal'
      style={customStyles}
    >
      <div className='animated fadeIn fixed inset-0 overflow-y-auto flex items-center justify-center'>
        <div className='max-h-screen w-full max-w-sm relative'>
          <div className='bg-legacyColor-secondary-50 rounded-3xl border border-legacyColor-neutral-2-500 p-4 mx-2 max-w-md text-center'>
            <div className='p-8'>
              <div className='text-center'>
                <Avatar
                  className='bg-primary-50 text-primary-900 rounded-full w-16 h-16'
                  name={avatarName}
                />
              </div>
              <p className='text-xl mt-4 font-bold'>
                Progress to {selectedPipelineStatusName}
              </p>
              <div className='mb-4 text-lg text-neutral-600 font-light flex flex-col'>
                <span>Are you sure you want to</span>
                <span>progress this candidate?</span>
              </div>

              <div className='flex justify-end items-center flex-wrap mt-6'>
                <button
                  className='btn btn-sm m-auto font-bold'
                  onClick={() => setSelectedPipelineStatus({})}
                >
                  Cancel
                </button>
                <button
                  className='btn btn-sm btn-primary m-auto'
                  onClick={onConfirm}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
