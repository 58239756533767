import { createSlice } from "@reduxjs/toolkit";
type SliceState = {
  isOpen: boolean;
};

const initialState: SliceState = {
  isOpen: false,
};

export const ModalSlice = createSlice({
  name: "jobRateRequiredModal",
  initialState,
  reducers: {
    open: (state, action) => {
      console.log(action.payload);
      return {
        ...state,
        isOpen: action.payload.isOpen,
      };
    },
    close: (state, action) => {
      return {
        ...state,
        isOpen: action.payload.isOpen,
      };
    },
  },
});

export const { open, close } = ModalSlice.actions;

const jobRateRequiredModalModalReducer = ModalSlice.reducer;
export { jobRateRequiredModalModalReducer };
