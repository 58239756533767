export const MessageBubbleComponent = ({
  currentUserClassName,
  senderName,
  senderClassName,
  content,
  createdAt,
}): JSX.Element => {
  return (
    <div
      className={
        senderClassName == currentUserClassName
          ? "bubble sender"
          : "bubble receiver"
      }
    >
      <div className='content'>
        <p className='whitespace-pre-line'>{content}</p>
      </div>
      <div className='timestamp'>
        <b>{senderName}</b> {createdAt}
      </div>
    </div>
  );
};
