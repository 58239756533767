import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { PipelineStatusConfirmaModal } from "./PipelineStatusConfirmModal";

import { updateJobOpeningCandidateStage } from "@/client_app/components/jobOpeningDetails/services/ProfileService";
import { update as updateBoard } from "@/client_app/components/jobOpeningDetails/store/BoardReducer";
import { open as openOfferModal } from "@/client_app/components/jobOpeningDetails/store/OfferJobOpeningCandidateModalReducer";
import { RootState } from "@/client_app/components/jobOpeningDetails/store/store";
import { flash } from "@/ui/Flash";

interface SelectedPipelineStatusInterface {
  selectedPipelineStatusValue?: string;
  selectedPipelineStatusName?: string;
}

export const PipelineStatusSelection = ({
  jobOpeningCandidateId,
  profileData,
  setProfileData,
}): JSX.Element => {
  const dispatch = useDispatch();
  const [selectedPipelineStatus, setSelectedPipelineStatus] =
    useState<SelectedPipelineStatusInterface>({});
  const [pipelineStatus, setPipelineStatus] = useState(
    profileData.pipelineStatus
  );

  const nextStage = useSelector(
    (state: RootState) => state.offerModal.nextStage
  );

  const currentStage = useSelector(
    (state: RootState) => state.offerModal.currentStage
  );

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const renderOfferStatus = (jobOfferStatus) => {
    let statusLabel = "offer ";
    let statusLabelClasses =
      "inline-flex text-xs font-semibold px-2.5 py-0.5 rounded-full mt-2 ";

    switch (jobOfferStatus) {
      case "accepted":
        statusLabelClasses += "bg-green-100 text-green-800";
        statusLabel += jobOfferStatus;
        break;
      case "rejected":
        statusLabelClasses += "bg-red-100 text-red-800";
        statusLabel += "declined";
        break;
      default:
        statusLabelClasses += "bg-blue-100 text-blue-800";
        statusLabel += jobOfferStatus;
    }

    return (
      <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        <div className={statusLabelClasses}>{statusLabel.toUpperCase()}</div>
        {isHovering && (
          <div className='bg-gray-50 p-1'>
            {profileData.jobOfferRejectReasons}
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    dispatch(
      openOfferModal({
        isOpen: false,
        currentStage: profileData.pipelineStatus,
      })
    );
  }, [profileData.pipelineStatus]);

  const { register } = useForm({
    defaultValues: {
      pipelineStatus: pipelineStatus,
    },
  });

  const onPipelineStatusChange = (event: InputEvent): void => {
    const target = event.target as HTMLSelectElement;
    const selectedPipelineStatusIdx = target.selectedIndex;
    const selectedPipelineStatusValue =
      target.options[selectedPipelineStatusIdx].value;
    const selectedPipelineStatusName =
      target.options[selectedPipelineStatusIdx].text;
    setPipelineStatus(selectedPipelineStatusValue);
    if (selectedPipelineStatusValue === "rejected_by_client") {
      setSelectedPipelineStatus({
        selectedPipelineStatusValue: selectedPipelineStatusValue,
        selectedPipelineStatusName: selectedPipelineStatusName,
      });
    } else if (selectedPipelineStatusValue === "placed") {
      dispatch(
        openOfferModal({
          isOpen: true,
          candidateName: profileData["avatarName"],
          jobOpeningCandidateId: jobOpeningCandidateId,
          stage: "Offer",
          nextStage: selectedPipelineStatusValue,
          currentStage: currentStage,
        })
      );
    } else {
      updatePipelineStatus(selectedPipelineStatusValue);
      dispatch(
        openOfferModal({
          currentStage: selectedPipelineStatusValue,
        })
      );
    }
  };

  const updatePipelineStatus = (selectedPipelineStatusValue) => {
    updateJobOpeningCandidateStage(
      jobOpeningCandidateId,
      selectedPipelineStatusValue
    ).then((response) => {
      if (response.ok) {
        flash.notice("PIPELINE STATUS CHANGED SUCCESSFULLY!");
        response.json().then(({ columns }) => {
          setSelectedPipelineStatus({});
          setProfileData({
            ...profileData,
            pipelineStatus: selectedPipelineStatusValue,
          });
          dispatch(
            updateBoard({
              columns: {
                Responded: columns.Responded,
                Shortlist: columns.Shortlist,
                Offer: columns.Offer,
                Rejected: columns.Rejected,
                "Dropped Out": columns["Dropped Out"],
              },
            })
          );
        });
      } else {
        flash.alert("COULD NOT CHANGE PIPELINE STATUS!");
      }
    });
  };

  const options = [
    ["responded", "RESPONDED"],
    ["shortlisted", "SHORTLIST"],
    ["placed", "OFFER"],
    ["rejected_by_client", "REJECTED"],
    ["dropped_out", "DROPPED OUT"],
  ];

  const renderOfferPipelineStatus = () => (
    <div className='p-0 my-5 md:px-5 md:my-0'>
      <label htmlFor='pipelineStatus' className='form-label font-bold mb-3'>
        PIPELINE STATUS
      </label>
      <div className='flex-inline border rounded-lg border-legacyColor-neutral-2-500 py-2 px-3 md:max-w-[165px]'>
        <span className='text-legacyColor-neutral-1-500 font-semibold text-sm mr-2'>
          OFFER
        </span>
        {profileData.jobOfferStatus === "sent" && (
          <span className='inline-block bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded-full'>
            Sent
          </span>
        )}
        {profileData.jobOfferStatus === "accepted" && (
          <span className='inline-block bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full'>
            Accepted
          </span>
        )}
        {profileData.jobOfferStatus === "rejected" && (
          <span className='inline-block bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full'>
            Declined
          </span>
        )}
      </div>
    </div>
  );

  const renderPipelineStatusForm = () => (
    <>
      <PipelineStatusConfirmaModal
        selectedPipelineStatus={selectedPipelineStatus}
        setSelectedPipelineStatus={setSelectedPipelineStatus}
        profileData={profileData}
        updatePipelineStatus={updatePipelineStatus}
      />
      <form className='form-control w-full p-0 my-5 md:px-5 md:my-0'>
        <label htmlFor='pipelineStatus' className='form-label font-bold mb-3'>
          PIPELINE STATUS
        </label>
        <select
          value={nextStage}
          id='pipelineStatus'
          className='form-select down-icon mb-2 bg-none'
          {...register("pipelineStatus", { onChange: onPipelineStatusChange })}
        >
          <option value='' hidden disabled>
            Please Choose
          </option>
          <>
            {options.map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </>
        </select>
        {profileData.jobOfferStatus &&
          renderOfferStatus(profileData.jobOfferStatus)}
      </form>
    </>
  );

  return (
    <>
      {pipelineStatus == "placed"
        ? renderOfferPipelineStatus()
        : renderPipelineStatusForm()}
    </>
  );
};
