import { createSlice } from "@reduxjs/toolkit";
type SliceState = {
  isOpen: boolean;
  candidateName: string;
  jobOpeningCandidateId: string;
  stage: string;
  columns: [];
};

const initialState: SliceState = {
  isOpen: false,
  candidateName: "",
  jobOpeningCandidateId: "",
  stage: "",
  columns: null,
};

export const ModalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    open: (state, action) => {
      return {
        ...state,
        isOpen: action.payload.isOpen,
        candidateName: action.payload.candidateName,
        jobOpeningCandidateId: action.payload.jobOpeningCandidateId,
        stage: action.payload.stage,
        columns: action.payload.columns,
      };
    },
    close: (state, action) => {
      return {
        ...state,
        isOpen: action.payload.isOpen,
        candidateName: "",
        jobOpeningCandidateId: "",
        stage: "",
        columns: null,
      };
    },
  },
});

export const { open, close } = ModalSlice.actions;

const JobOpeningCandidateModalReducer = ModalSlice.reducer;
export { JobOpeningCandidateModalReducer };
