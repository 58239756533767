// @flow
import { open as openRejectModal } from "packs/client_app/components/jobOpeningDetails/store/JobOpeningCandidateModalReducer";
import { open as openJobRateRequiredModal } from "packs/client_app/components/jobOpeningDetails/store/JobRateRequiredModalReducer";
import { open as openOfferModal } from "packs/client_app/components/jobOpeningDetails/store/OfferJobOpeningCandidateModalReducer";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { Column } from "./Column";
import { EmptyStage } from "./EmptyStage";

import { reorderItemMap } from "@/client_app/components/jobOpeningDetails/ordered";
import { ProfileModal } from "@/client_app/components/jobOpeningDetails/profile/ProfileModal";
import { getJobOpeningCandidate } from "@/client_app/components/jobOpeningDetails/services/ProfileService";
import { requestChangeStage } from "@/client_app/components/jobOpeningDetails/services/StageService";
import { update } from "@/client_app/components/jobOpeningDetails/store/BoardReducer";
import { RootState } from "@/client_app/components/jobOpeningDetails/store/store";
import { flash } from "@/ui/Flash";

const Container = styled.div`
  min-height: 100vh;
  /* like display:flex but will allow bleeding over the window width */
  width: 100%;
  display: inline-flex;
  backgroud-color: white;
`;

export const JobOpeningBoard = ({
  initial,
  withScrollableColumns,
  jobOpeningId,
  hasRate,
  jobOpeningDetailEmptyStageImageURL,
}) => {
  const [currentJobOpeningCandidateId, setCurrentJobOpeningCandidateId] =
    useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof jobOpeningId === "string" && jobOpeningId.trim().length > 0)
      dispatch(update({ columns: initial }));
  }, [jobOpeningId]);

  const columns = useSelector((state: RootState) => state.board.columns);
  const notDraggableColumns = ["Dropped Out", "Offer"];

  const [ordered, setOrdered] = useState(Object.keys(initial));

  const onDragEnd = (result) => {
    const sourceColumn = columns[result.source.droppableId];
    const sourceIndex = result.source.index;
    const jobOpeningCandidate = sourceColumn[sourceIndex];
    const candidateName = jobOpeningCandidate["candidate"]["avatarName"];

    if (result.combine) {
      if (result.type === "COLUMN") {
        const shallow = [...ordered];
        shallow.splice(sourceIndex, 1);
        setOrdered(shallow);
        return;
      }

      const withItemRemoved = [...sourceColumn];

      withItemRemoved.splice(sourceIndex, 1);
      const orderedColumns = {
        ...columns,
        [result.source.droppableId]: withItemRemoved,
      };
      dispatch(update({ columns: orderedColumns }));
      return;
    }

    // dropped nowhere
    if (!result.destination) return;

    const source = result.source;
    const destination = result.destination;
    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const data = reorderItemMap({
      itemMap: columns,
      source,
      destination,
    });

    if (result.destination.droppableId == "Rejected") {
      dispatch(
        openRejectModal({
          isOpen: true,
          candidateName: candidateName,
          jobOpeningCandidateId: result.draggableId,
          stage: result.destination.droppableId,
          columns: data.itemMap,
        })
      );
    } else if (result.destination.droppableId == "Offer") {
      if (hasRate) {
        getJobOpeningCandidate(result.draggableId)
          .then((response) => response.json())
          .then((data) => {
            dispatch(
              openOfferModal({
                isOpen: true,
                candidateName: candidateName,
                jobOpeningCandidateId: result.draggableId,
                stage: result.destination.droppableId,
                columns: data.itemMap,
                candidateLocation: data.location,
                remoteWorkingLocation: data.remoteWorkingLocation,
              })
            );
          });
      } else {
        dispatch(openJobRateRequiredModal({ isOpen: true }));
      }
    } else {
      requestChangeStage(
        result.draggableId,
        result.destination.droppableId,
        null,
        null
      )
        .then((response) => {
          flash.notice("Stage changed!");
          return response.json();
        })
        .catch(() => {
          flash.alert("Change stage failed!");
        })
        .then(({ columns }) => {
          dispatch(update({ columns: columns }));
        });
    }
  };

  let isEmptyStage = true;
  for (const key in initial) {
    isEmptyStage = isEmptyStage && (initial[key] as Array<object>).length == 0;
  }

  if (!columns) return;
  if (isEmptyStage) {
    return (
      <EmptyStage
        jobOpeningDetailEmptyStageImageURL={jobOpeningDetailEmptyStageImageURL}
      />
    );
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='board' type='COLUMN' direction='horizontal'>
          {(provided) => (
            <Container ref={provided.innerRef} {...provided.droppableProps}>
              {ordered.map((key, index) => (
                <Column
                  key={key}
                  index={index}
                  title={key}
                  items={columns[key]}
                  isScrollable={withScrollableColumns}
                  isDragDisabled={notDraggableColumns.includes(key)}
                  isTheLastColumn={index == ordered.length - 1}
                  setCurrentJobOpeningCandidateId={
                    setCurrentJobOpeningCandidateId
                  }
                />
              ))}
              {provided.placeholder}
            </Container>
          )}
        </Droppable>
      </DragDropContext>
      <ProfileModal
        closeModal={() => setCurrentJobOpeningCandidateId("")}
        currentJobOpeningCandidateId={currentJobOpeningCandidateId}
      />
    </>
  );
};
