import { useState } from "react";
import Avatar from "react-avatar";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";

import { requestChangeStage } from "./services/StageService";
import { update as updateBoard } from "./store/BoardReducer";
import { close } from "./store/JobOpeningCandidateModalReducer";
import type { RootState } from "./store/store";

import { flash } from "@/ui/Flash";

Modal.setAppElement("body");

export const JobRejectModal = (props): JSX.Element => {
  const { rejectOptions } = props;
  const customStyles = {
    overlay: {
      zIndex: 9999,
      opacity: 1,
      "background-color": "rgba(0, 0, 0, 0.3)",
    },
    content: {
      background: "none",
      border: "none",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      padding: "0",
    },
  };
  const dispatch = useDispatch();
  const isOpen = useSelector((state: RootState) => state.modal.isOpen);
  const stage = useSelector((state: RootState) => state.modal.stage);

  const [rejectReason, setRejectReason] = useState("");
  const [rejectDetail, setRejectDetail] = useState("");
  const jobOpeningCandidateId = useSelector(
    (state: RootState) => state.modal.jobOpeningCandidateId
  );
  const candidateName = useSelector(
    (state: RootState) => state.modal.candidateName
  );

  const onConfirm = async () => {
    const response = await requestChangeStage(
      jobOpeningCandidateId,
      stage,
      null,
      null,
      rejectReason,
      rejectDetail
    );

    const data = await response.json();

    if (response.ok) {
      const columns = data.columns;
      flash.notice("Stage changed!");
      dispatch(close({ isOpen: false }));
      dispatch(
        updateBoard({
          columns: {
            Responded: columns.Responded,
            Shortlist: columns.Shortlist,
            Offer: columns.Offer,
            Rejected: columns.Rejected,
            "Dropped Out": columns["Dropped Out"],
          },
        })
      );
      dispatch(close({ isOpen: false }));
    } else {
      dispatch(close({ isOpen: false }));
      flash.notice(data.error);
    }
  };

  const onCancel = () => {
    setRejectReason("");
    setRejectDetail("");
    dispatch(close({ isOpen: false }));
  };

  const onReasonChange = (event) => {
    setRejectReason(event.target.value);
  };

  const onDetailChange = (event) => {
    setRejectDetail(event.target.value);
  };

  const disabledSubmitBtn = () => {
    if (!rejectReason) return true;
    if (rejectReason === "other" && !rejectDetail) return true;

    return false;
  };

  return (
    <Modal isOpen={isOpen} contentLabel='Example Modal' style={customStyles}>
      <div className='animated fadeIn fixed inset-0 overflow-y-auto flex items-center justify-center'>
        <div className='max-h-screen w-full max-w-sm relative'>
          <div className='bg-legacyColor-secondary-50 rounded-3xl border border-legacyColor-neutral-2-500 p-4 mx-2 max-w-md text-center'>
            <div className='p-8'>
              <div className='text-center'>
                <Avatar
                  className='bg-primary-50 text-primary-900 rounded-full w-16 h-16'
                  name={candidateName}
                />
              </div>
              <p className='text-xl mt-4 font-bold'>Reject candidate</p>
              <div className='mb-4 text-lg text-neutral-600 font-light flex flex-col'>
                <span>
                  To help improve our matching, select a reason for rejecting
                  the candidate:
                </span>

                <div className='mt-4'>
                  <div onChange={(event) => onReasonChange(event)}>
                    {rejectOptions.map((option) => {
                      return (
                        <div className='flex gap-1' key={option.id}>
                          <input
                            className='form-radio'
                            type='radio'
                            value={option.id}
                            id={option.id}
                            name='reason'
                          />

                          <label className='form-label' htmlFor={option.id}>
                            {option.label}
                          </label>
                        </div>
                      );
                    })}
                  </div>

                  {rejectReason === "other" && (
                    <div className='mt-4' id='detail_container'>
                      <label className='form-label text-left'>Detail</label>
                      <textarea
                        onChange={onDetailChange}
                        className='form-control'
                        placeholder='e.g. Not suitable'
                        rows={4}
                        name='detail'
                      ></textarea>
                    </div>
                  )}
                </div>
              </div>

              <div className='flex justify-end items-center flex-wrap mt-6'>
                <button
                  className='btn btn-sm m-auto font-bold'
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  className='btn btn-sm btn-primary m-auto'
                  onClick={onConfirm}
                  disabled={disabledSubmitBtn()}
                  id='submitBtn'
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
