import Modal from "react-modal";

interface RequestHelpModalProps {
  isOpen: boolean;
  isSuccess: boolean;
  closeModal: () => void;
}

Modal.setAppElement("body");

export const RequestHelpModal = ({
  isOpen,
  isSuccess,
  closeModal,
}: RequestHelpModalProps): JSX.Element => {
  const customStyles = {
    overlay: {
      zIndex: 9999,
      backgroundColor: "rgba(9, 32, 36, 0.9)",
    },
    content: {
      background: "none",
      border: "none",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      padding: "0",
    },
  };

  return (
    <Modal isOpen={isOpen} contentLabel='Example Modal' style={customStyles}>
      <div className='animated fadeIn fixed inset-0 overflow-y-auto flex items-center justify-center'>
        <div className='max-h-screen w-full max-w-lg relative'>
          <div className='bg-legacyColor-secondary-50 rounded-3xl border border-legacyColor-neutral-2-500 p-4 mx-auto max-w-md text-center'>
            <div className='p-8'>
              <h2 className={"text-xl mb-4 font-bold"}>
                {isSuccess ? (
                  "Help is on its way"
                ) : (
                  <span className='text-lg text-legacyColor-semantic-warning-200'>
                    ERROR WHEN PROCESSING YOUR REQUEST
                  </span>
                )}
              </h2>
              <p className='mb-4'>
                {isSuccess
                  ? "Your message has been sent and a Weploy team member will reach out to you shortly"
                  : "Please try again later"}
              </p>

              <div className='flex justify-end items-center flex-wrap mt-6'>
                <button
                  className='btn btn-sm btn-primary m-auto'
                  onClick={closeModal}
                >
                  OKAY
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
