// import PropTypes from "prop-types";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";

import { JobOpeningBoard } from "./board/Board";
import { JobDetails } from "./JobDetails";
import { JobRateRequiredModal } from "./JobRateRequiredModal";
import { JobRejectModal } from "./JobRejectModal";
import { OfferDetailModal } from "./OfferDetailModal";
import { RequestHelp } from "./RequestHelp";
import { store } from "./store/store";

interface CandidateProps {
  id: string;
  fullName: string;
}

interface JobOpeningCandidateProps {
  id: string;
  jobOpeningId: string;
  stage: string;
  message: string;
  stageChangeAt: string;
  candidate: CandidateProps;
  length: number;
}

interface IndexProps {
  jobPipelineEnabled: boolean;
  requestHelpEnabled: boolean;
  salespersonName: string;
  salespersonPhoneNumber: string;
  salespersonEmail: string;
  talentDepartmentContactEmail: string;
  financeDepartmentContactEmail: string;
  isFinalizedSetup: boolean;
  jobOpeningId: string;
  jobOpeningUrl: string;
  activeTab: string;
  terminologyOrganization: string;
  rejectOptions: Array<object>;
  hasRate: boolean;
  Responded: JobOpeningCandidateProps;
  "Dropped Out": JobOpeningCandidateProps;
  Shortlist: JobOpeningCandidateProps;
  Offer: JobOpeningCandidateProps;
  Rejected: JobOpeningCandidateProps;
  jobOpeningDetailEmptyStageImageURL: string;
}

const JobOpeningDetails = ({ ...props }: IndexProps): JSX.Element => {
  const {
    terminologyOrganization,
    jobPipelineEnabled,
    requestHelpEnabled,
    activeTab,
    jobOpeningId,
    jobOpeningUrl,
    rejectOptions,
    hasRate,
    jobOpeningDetailEmptyStageImageURL,
  } = props;
  const [currentTab, setCurrentTab] = useState(activeTab);
  const jobOpeningCandidateData = {
    Responded: props.Responded || [],
    Shortlist: props.Shortlist || [],
    Offer: props.Offer || [],
    Rejected: props.Rejected || [],
    "Dropped Out": props["Dropped Out"] || [],
  };

  if (jobOpeningCandidateData["Dropped Out"].length == 0)
    delete jobOpeningCandidateData["Dropped Out"];

  const onTabClick = (tabName) => {
    if (tabName == "Pipeline") {
      window.location.replace(jobOpeningUrl);
    } else if (tabName == "Details") {
      window.location.replace(jobOpeningUrl + "?active_tab=Details");
    } else {
      setCurrentTab(tabName);
    }
  };

  const renderNavigationBar = (): JSX.Element => {
    const tabNames = ["Pipeline", "Details", "Request Help"];
    if (!jobPipelineEnabled && !requestHelpEnabled) return;

    return (
      <div className='flex space-x-8 md:flex-row mb-12'>
        {tabNames.map((name, index) => {
          const isActive = name == currentTab;

          if (
            (name == "Pipeline" && !jobPipelineEnabled) ||
            (name == "Request Help" && !requestHelpEnabled)
          )
            return;

          return (
            <button
              className={`flex gap-1 items-center font-bold uppercase pb-1 ${
                isActive ? "border-b-2 border-primary-700" : ""
              }`}
              onClick={() => onTabClick(name)}
              aria-current='page'
              key={`tab_${index}`}
            >
              <div
                className={isActive ? "text-secondary-700" : "text-neutral-500"}
              >
                {name}
              </div>
            </button>
          );
        })}
      </div>
    );
  };

  const renderContent = () => {
    if (currentTab == "Request Help" && requestHelpEnabled) {
      return <RequestHelp {...props} />;
    } else if (currentTab == "Details") {
      return <JobDetails {...props} />;
    } else {
      return (
        <>
          <JobOpeningBoard
            initial={jobOpeningCandidateData}
            jobOpeningId={jobOpeningId}
            hasRate={hasRate}
            withScrollableColumns
            jobOpeningDetailEmptyStageImageURL={
              jobOpeningDetailEmptyStageImageURL
            }
          />
        </>
      );
    }
  };

  return (
    <Provider store={store}>
      {renderNavigationBar()}
      {renderContent()}
      <Toaster
        position='top-center'
        toastOptions={{
          duration: 5000,
        }}
      />
      <JobRejectModal rejectOptions={rejectOptions} />
      <OfferDetailModal {...props} />
      <JobRateRequiredModal terminologyOrganization={terminologyOrganization} />
    </Provider>
  );
};

export { JobOpeningDetails };
