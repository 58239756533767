import { configureStore } from "@reduxjs/toolkit";

import { BoardReducer } from "./BoardReducer";
import { JobOpeningCandidateModalReducer } from "./JobOpeningCandidateModalReducer";
import { jobRateRequiredModalModalReducer } from "./JobRateRequiredModalReducer";
import { OfferJobOpeningCandidateModalReducer } from "./OfferJobOpeningCandidateModalReducer";

export const store = configureStore({
  reducer: {
    modal: JobOpeningCandidateModalReducer,
    board: BoardReducer,
    offerModal: OfferJobOpeningCandidateModalReducer,
    jobRateRequiredModal: jobRateRequiredModalModalReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
