interface IClickEventAttributes {
  click_text: string;
}

interface IClickCheckboxEventAttributes {
  checkbox_name: string;
  checkbox_label: string;
  checked: string;
}

interface ISelectEventAttributes {
  select_label: string;
  selected_name: string;
  selected_value: string;
}

const gaTrackNoop: Gtag.Gtag = (): void => {
  // do nothing
};

const gtag = window.gtag || gaTrackNoop;

export const gaClickTrack = (
  attributes: IClickEventAttributes | IClickCheckboxEventAttributes
): void => {
  gtag("event", "click", {
    event_category: "engagement",
    event_label: "click",
    ...attributes,
  });
};

export const gaSelectTrack = (attributes: ISelectEventAttributes): void => {
  gtag("event", "select", {
    event_category: "engagement",
    event_label: "select",
    ...attributes,
  });
};
