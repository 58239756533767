import React, { useState, useEffect } from "react";
import Avatar from "react-avatar";
import SVG from "react-inlinesvg";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

import Cross from "@assets/icons/cross.svg";

import { MessageInterface, Message } from "./Message";
import { PipelineStatusSelection } from "./PipelineStatusSelection";
import { ProfileContent } from "./ProfileContent";
import { StudentProfileContent } from "./StudentProfileContent";
import { WeployeeInformation } from "./WeployeeInformation";

import { updateUnreadMessages } from "@/client_app/components/jobOpeningDetails/services/MessageService";
import { getJobOpeningCandidate } from "@/client_app/components/jobOpeningDetails/services/ProfileService";
import { update } from "@/client_app/components/jobOpeningDetails/store/BoardReducer";
import { RootState } from "@/client_app/components/jobOpeningDetails/store/store";

import "./profileModal.scss";

interface WorkExperienceProps {
  experienceType: string;
  title: string;
  organizationName: string;
  startAndEndDate: string;
  summary: string;
}

interface StudentClubProps {
  tite: string;
  startAndEndDate: string;
  summary: string;
}
interface GroupedWorkingExperiencesProps {
  work: Array<WorkExperienceProps>;
  project: Array<WorkExperienceProps>;
  volunteering: Array<WorkExperienceProps>;
}

interface StudentGeneralInformationProps {
  studentId: string;
  firstName: string;
  surname: string;
  preferredName: string;
  emergencyContact: string;
  emergencyPhone: string;
  aboriginalOrTorresStraitIslandOrigin: string;
  workworkRights_rights: string;
  noticePeriod: string;
}

interface StudentAcademicRecordProps {
  faculty: string;
  courseTitle: string;
  courseEnrolledSubjectsCompleted: string;
  courseEnrolledSubjectsEnrolledIn: string;
  expectedCourseCompletion: string;
  weightedAverageMark: string;
}

interface StudentProfessionalDetailsProps {
  groupedWorkingExperiences?: GroupedWorkingExperiencesProps;
  studentClubs?: Array<StudentClubProps>;
  linkedin?: string;
}
interface CandidateProfile {
  content?: string;
  generalInformation?: StudentGeneralInformationProps;
  academicRecord?: Array<StudentAcademicRecordProps>;
  professionalDetails: StudentProfessionalDetailsProps;
}

interface ProfileData {
  jobOpeningCandidateId?: string;
  avatarName?: string;
  fullName?: string;
  weployeeStatus?: string;
  skills?: string;
  location?: string;
  wam?: string;
  covidVaccination?: string;
  visaStatus?: string;
  pipelineStatus?: string;
  candidateProfile?: CandidateProfile;
  inAppMessages?: Array<MessageInterface>;
  currentUserClassName?: string;
  lastestMessageCreatedAt?: string;
  jobOfferStatus?: string;
  isStudent?: boolean;
}

interface ProfileModalProps {
  currentJobOpeningCandidateId: string;
  closeModal: () => void;
}

Modal.setAppElement("body");

export const ProfileModal = ({
  currentJobOpeningCandidateId,
  closeModal,
}: ProfileModalProps): JSX.Element => {
  const [profileData, setProfileData] = useState<ProfileData>({});
  const {
    avatarName,
    weployeeStatus,
    skills,
    location,
    covidVaccination,
    visaStatus,
    candidateProfile,
    inAppMessages,
    currentUserClassName,
    wam,
    fullName,
    isStudent,
  } = profileData;
  const dispatch = useDispatch();
  const columns = useSelector((state: RootState) => state.board.columns);

  const updateBoard = (
    jobOpeningCandidateId,
    newestProfileData,
    updateUnreadMessagesSuccess
  ) => {
    const stageToColumnName = {
      responded: "Responded",
      shortlisted: "Shortlist",
      placed: "Offer",
      rejected_by_client: "Rejected",
      dropped_out: "Dropped Out",
    };

    const newColumns = Object.values(columns)
      .flat()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .reduce(
        (acc: any, currentItem: any) => {
          const columnName = stageToColumnName[currentItem.stage];
          const inAppMessages = newestProfileData.inAppMessages || [];
          const unreadMessageCount = inAppMessages.filter((msg) => {
            return msg.status == "sent" && !msg.toParticipantId;
          }).length;
          const lastestMessageCreatedAt =
            newestProfileData.lastestMessageCreatedAt;
          const newItem =
            currentItem.id == jobOpeningCandidateId
              ? {
                  ...currentItem,
                  lastestMessageCreatedAt: lastestMessageCreatedAt,
                  messageCount: inAppMessages.length,
                  unreadMessageCount: updateUnreadMessagesSuccess
                    ? 0
                    : unreadMessageCount,
                }
              : { ...currentItem };
          acc[columnName].push(newItem);
          return acc;
        },
        {
          Responded: [],
          Shortlist: [],
          Offer: [],
          Rejected: [],
          "Dropped Out": [],
        }
      );

    dispatch(
      update({
        columns: newColumns,
      })
    );
  };

  const closeProfileModal = () => {
    setProfileData({});
    closeModal();
  };

  const fetchProfileData = () =>
    getJobOpeningCandidate(currentJobOpeningCandidateId)
      .then((response) => response.json())
      .then((data) => {
        setProfileData(data);
        updateStatusForUnreadMessages(currentJobOpeningCandidateId, data);
      });

  const updateStatusForUnreadMessages = (
    currentJobOpeningCandidateId: string,
    data: ProfileData
  ) => {
    const unreadMessageIds = (data.inAppMessages || [])
      .filter((msg) => {
        return msg.status == "sent" && !msg.toParticipantId;
      })
      .map((msg) => msg.id);

    if (
      currentJobOpeningCandidateId &&
      unreadMessageIds &&
      unreadMessageIds.length > 0
    ) {
      updateUnreadMessages(currentJobOpeningCandidateId, unreadMessageIds).then(
        (response) => {
          updateBoard(currentJobOpeningCandidateId, data, response.ok);
        }
      );
    } else {
      updateBoard(currentJobOpeningCandidateId, data, false);
    }
  };

  useEffect(() => {
    if (
      typeof currentJobOpeningCandidateId === "string" &&
      currentJobOpeningCandidateId.trim().length > 0
    ) {
      fetchProfileData();
    }
  }, [currentJobOpeningCandidateId]);

  const renderProfileContent = () => {
    if (isStudent) {
      return (
        <StudentProfileContent candidateProfile={candidateProfile} wam={wam} />
      );
    } else {
      return <ProfileContent candidateProfile={candidateProfile} />;
    }
  };

  const renderWeployeeBaseInfo = () => {
    if (isStudent) {
      return (
        <div className='w-full relative h-full'>
          <h3 className='absolute -translate-y-2/4 top-1/2'>{fullName}</h3>
        </div>
      );
    } else {
      return (
        <WeployeeInformation
          skills={skills}
          location={location}
          covidVaccination={covidVaccination}
          visaStatus={visaStatus}
        />
      );
    }
  };

  return (
    <Modal
      isOpen={
        typeof currentJobOpeningCandidateId === "string" &&
        currentJobOpeningCandidateId.trim().length > 0
      }
      contentLabel='Example Modal'
      style={{
        overlay: {
          position: "fixed",
          zIndex: 1020,
          top: 0,
          right: 0,
          width: "100vw",
          height: "100%",
          display: "flex",
          justifyContent: "right",
          backgroundColor: "rgba(9, 32, 36, 0.8)",
        },
        content: {
          backgroundColor: "#FFF",
          inset: 0,
          width: "85%",
          height: "100%",
          position: "relative",
          border: "1px solid #9CA1A0",
          borderRadius: "0.3rem",
          padding: 0,
        },
      }}
    >
      <section className='text-legacyColor-neutral-1-800'>
        <div className='flex items-center relative text-shade-100 border-b p-2'>
          <button onClick={closeProfileModal}>
            <SVG
              src={Cross}
              className='icon fill-current mr-4 cursor-pointer'
            />
          </button>
        </div>
        <div className='p-6'>
          <div className='flex'>
            <div className='w-40 mr-6 md:float-left'>
              <Avatar
                className='bg-primary-50 text-primary-900 rounded-full h-40 w-40 text-6xl'
                name={avatarName}
              />
            </div>
            <div className='flex-1'>
              <div className='md:col-span-4 h-full'>
                {renderWeployeeBaseInfo()}
              </div>
            </div>
            <div className='flex w-64'>
              {Object.keys(profileData).length && (
                <PipelineStatusSelection
                  profileData={profileData}
                  jobOpeningCandidateId={currentJobOpeningCandidateId}
                  setProfileData={setProfileData}
                />
              )}
            </div>
          </div>
          <div className='md:grid md:gap-8 md:grid-cols-7 lg:grid-cols-3 mt-6'>
            <div className='md:col-span-4 lg:col-span-2 py-3 md:px-3 border-t border-b md:border-0'>
              {renderProfileContent()}
            </div>
            <div className='md:col-span-3 lg:col-span-1 py-3 md:px-3'>
              <Message
                currentUserClassName={currentUserClassName}
                jobOpeningCandidateId={currentJobOpeningCandidateId}
                messages={inAppMessages}
                fetchProfileData={fetchProfileData}
                weployeeStatus={weployeeStatus}
              />
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
};
