import { close as closeJobRateRequiredModal } from "packs/client_app/components/jobOpeningDetails/store/JobRateRequiredModalReducer";
import SVG from "react-inlinesvg";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";

import CancelSVG from "@assets/icons/cancel.svg";

import { RootState } from "./store/store";

Modal.setAppElement("body");

export const JobRateRequiredModal = ({
  terminologyOrganization,
}): JSX.Element => {
  const isOpen = useSelector(
    (state: RootState) => state.jobRateRequiredModal.isOpen
  );
  const dispatch = useDispatch();

  const customStyles = {
    overlay: {
      zIndex: 9999,
      opacity: 1,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
    content: {
      background: "none",
      border: "none",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      padding: "0",
    },
  };

  const onCancel = () => {
    dispatch(closeJobRateRequiredModal({ isOpen: false }));
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel='JobRateRequireModal'
      style={customStyles}
    >
      <div className='animated fadeIn fixed inset-0 overflow-y-auto flex items-center justify-center'>
        <div className='max-h-screen mx-auto relative min-w-[500px]'>
          <div className='bg-legacyColor-secondary-50 rounded-3xl border border-legacyColor-neutral-2-500 p-4 mx-2 max-w-md text-center'>
            <SVG
              src={CancelSVG}
              className='icon fill-current mr-4 cursor-pointer absolute'
              onClick={onCancel}
            />
            <p className='text-xl mt-4 font-bold'>Job Rate Required</p>
            <div className='mb-4 text-lg text-neutral-600 font-light flex flex-col'>
              <span>
                Please contact {terminologyOrganization} to set up a rate
              </span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
