import ReactOnRails from "react-on-rails";

import { JobOpeningDetails } from "./client_app/components/jobOpeningDetails/Index";
import { JobDetails } from "./client_app/components/jobOpeningDetails/JobDetails";
import { RequestHelp } from "./client_app/components/jobOpeningDetails/RequestHelp";

ReactOnRails.register({
  RequestHelp,
  JobDetails,
  JobOpeningDetails,
});
