import React from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

import { JobOpeningCandidateList } from "./list";
import { Title } from "./title";

import { borderRadius } from "@/client_app/components/jobOpeningDetails/styles/constants";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: ${borderRadius}px;
  border-top-right-radius: ${borderRadius}px;
  transition: background-color 0.2s ease;
`;

export const Column = (props) => {
  const title = props.title;
  const items = props.items;
  const index = props.index;
  const isDragDisabled = props.isDragDisabled;
  const setCurrentJobOpeningCandidateId = props.setCurrentJobOpeningCandidateId;

  return (
    <Draggable
      draggableId={title}
      index={index}
      isDropDisabled={true}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <Container ref={provided.innerRef} {...provided.draggableProps}>
          <Header isDragging={snapshot.isDragging}>
            <Title
              isDragging={snapshot.isDragging}
              {...provided.dragHandleProps}
              aria-label={`${title} item list`}
            >
              {title} ({items ? items.length : 0})
            </Title>
          </Header>
          <JobOpeningCandidateList
            listId={title}
            listType='JobOpeningCandidate'
            items={items}
            internalScroll={props.isScrollable}
            isCombineEnabled={Boolean(props.isCombineEnabled)}
            useClone={Boolean(props.useClone)}
            index={index}
            isTheLastColumn={props.isTheLastColumn}
            setCurrentJobOpeningCandidateId={setCurrentJobOpeningCandidateId}
            isDragDisabled={isDragDisabled}
          />
        </Container>
      )}
    </Draggable>
  );
};
