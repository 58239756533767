import pluralize from "pluralize";
import Avatar from "react-avatar";
import SVG from "react-inlinesvg";

import clockIcon from "@assets/icons/clock.svg";
import messageIcon from "@assets/icons/message.svg";
import unreadMessageIcon from "@assets/icons/unread_message.svg";

// Previously this extended React.Component
// That was a good thing, because using React.PureComponent can hide
// issues with the selectors. However, moving it over does can considerable
// performance improvements when reordering big lists (400ms => 200ms)
// Need to be super sure we are not relying on PureComponent here for
// things we should be doing in the selector as we do not know if consumers
// will be using PureComponent
export const JobOpeningCandidate = (props) => {
  const {
    content,
    isDragging,
    provided,
    index,
    setCurrentJobOpeningCandidateId,
  } = props;

  const renderOfferStatus = (jobOfferStatus) => {
    let statusLabel = "offer ";
    let statusLabelClasses =
      "inline-flex text-xs font-semibold px-2.5 py-0.5 rounded-full mt-2 ";

    switch (jobOfferStatus) {
      case "accepted":
        statusLabelClasses += "bg-green-100 text-green-800";
        statusLabel += jobOfferStatus;
        break;
      case "rejected":
        statusLabelClasses += "bg-red-100 text-red-800";
        statusLabel += "declined";
        break;
      default:
        statusLabelClasses += "bg-blue-100 text-blue-800";
        statusLabel += jobOfferStatus;
    }

    return (
      <div className={statusLabelClasses}>{statusLabel.toUpperCase()}</div>
    );
  };

  return (
    <div
      className='border-neutral-300 border-2 h-30 p-4 rounded-2xl mb-4 bg-legacyColor-secondary-50'
      key={`weployeeCard_${content.candidate.avatarName}`}
      href={content.url}
      onClick={() => setCurrentJobOpeningCandidateId(content.id)}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      data-is-dragging={isDragging}
      data-index={index}
      aria-label={`${content.candidate.fullName}`}
    >
      <div className='flex space-x-4 items-center'>
        <Avatar
          className='bg-primary-50 text-primary-900 rounded-full w-16 h-16'
          name={content.candidate.avatarName}
        />
        <div>
          {content.shouldDisplayCandidateName && (
            <p className='font-semibold mb-2'>{content.candidate.fullName}</p>
          )}

          <div className='flex items-center space-x-2 mb-2'>
            <SVG
              className='w-4 h-4'
              src={
                content.unreadMessageCount > 0 ? unreadMessageIcon : messageIcon
              }
            />
            {content.unreadMessageCount > 0 ? (
              <p>{content.unreadMessageCount} unread</p>
            ) : (
              <p>{pluralize("message", content.messageCount, true)}</p>
            )}
          </div>
          {content.lastestMessageCreatedAt && (
            <div className='flex space-x-2'>
              <SVG className='w-4 h-4' src={clockIcon} />
              <p>{content.lastestMessageCreatedAt}</p>
            </div>
          )}
          {content.jobOfferStatus && renderOfferStatus(content.jobOfferStatus)}
        </div>
      </div>
    </div>
  );
};
