import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

import { JobOpeningCandidate } from "./item";
import { Title } from "./title";

import { grid } from "@/client_app/components/jobOpeningDetails/styles/constants";

export const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
  if (isDraggingOver) {
    return "#DDF8F4";
  }
  if (isDraggingFrom) {
    return "#E6FCFF";
  }
  return "#FFF";
};

const Wrapper = styled.div`
  background-color: ${(props) =>
    getBackgroundColor(props.isDraggingOver, props.isDraggingFrom)};
  display: flex;
  flex-direction: column;
  opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : "inherit")};
  padding: ${grid * 2}px;
  border: ${grid}px;
  padding-bottom: 0;
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
  width: 100%;
  height: calc(100% - 208px);
  &:nth-child(odd) {
    border-left: 2px solid #a0b3b7;
  }
  &:nth-child(even) {
    border-right: ${({ isTheLastColumn }) =>
      isTheLastColumn == true ? "none" : "2px solid #a0b3b7"};
  }
`;

const DropZone = styled.div`
  height: 100%;
  padding-bottom: ${grid}px;
`;

const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
`;

/* stylelint-disable block-no-empty */
const Container = styled.div`
  height: 100%;
`;
/* stylelint-enable */

const InnerItemList = (props) => {
  const { items, isDragDisabled } = props;

  return (items || []).map((item, index) => (
    <Draggable
      key={item.id}
      draggableId={item.id}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(dragProvided, dragSnapshot) => (
        <JobOpeningCandidate
          key={item.id}
          content={item}
          isDragging={dragSnapshot.isDragging}
          isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
          provided={dragProvided}
          setCurrentJobOpeningCandidateId={
            props.setCurrentJobOpeningCandidateId
          }
        />
      )}
    </Draggable>
  ));
};

const InnerList = (props) => {
  const { items, dropProvided, isDragDisabled } = props;
  const title = props.title ? <Title>{props.title}</Title> : null;

  return (
    <Container>
      {title}
      <DropZone ref={dropProvided.innerRef}>
        <InnerItemList
          items={items}
          setCurrentJobOpeningCandidateId={
            props.setCurrentJobOpeningCandidateId
          }
          isDragDisabled={isDragDisabled}
        />
        {dropProvided.placeholder}
      </DropZone>
    </Container>
  );
};

export const JobOpeningCandidateList = (props) => {
  const {
    ignoreContainerClipping,
    internalScroll,
    scrollContainerStyle,
    isDropDisabled,
    isCombineEnabled,
    listId = "LIST",
    listType,
    style,
    items,
    title,
    useClone,
    isTheLastColumn,
    setCurrentJobOpeningCandidateId,
    isDragDisabled,
  } = props;

  return (
    <Droppable
      droppableId={listId}
      type={listType}
      ignoreContainerClipping={ignoreContainerClipping}
      isDropDisabled={isDropDisabled}
      isCombineEnabled={isCombineEnabled}
      renderClone={
        useClone
          ? (provided, snapshot, descriptor) => (
              <JobOpeningCandidate
                content={items[descriptor.source.index]}
                provided={provided}
                isDragging={snapshot.isDragging}
                isClone
              />
            )
          : null
      }
    >
      {(dropProvided, dropSnapshot) => (
        <Wrapper
          isTheLastColumn={isTheLastColumn}
          style={style}
          isDraggingOver={dropSnapshot.isDraggingOver}
          isDropDisabled={isDropDisabled}
          isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
          {...dropProvided.droppableProps}
        >
          {internalScroll ? (
            <ScrollContainer style={scrollContainerStyle}>
              <InnerList
                items={items}
                title={title}
                dropProvided={dropProvided}
                setCurrentJobOpeningCandidateId={
                  setCurrentJobOpeningCandidateId
                }
                isDragDisabled={isDragDisabled}
              />
            </ScrollContainer>
          ) : (
            <InnerList
              items={items}
              title={title}
              dropProvided={dropProvided}
              setCurrentJobOpeningCandidateId={setCurrentJobOpeningCandidateId}
              isDragDisabled={isDragDisabled}
            />
          )}
        </Wrapper>
      )}
    </Droppable>
  );
};
