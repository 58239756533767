import PropTypes from "prop-types";

interface ProfileContentProps {
  candidateProfile?: {
    content?: string;
  };
}

export const ProfileContent = ({
  candidateProfile,
}: ProfileContentProps): JSX.Element => {
  const { content } = candidateProfile || {};

  const htmlContent = () => {
    if (content != undefined) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: content
              .toString()
              .replace(/(<? *script)/gi, "illegalscript"),
          }}
        ></div>
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <h5 className='font-bold mb-6'>Profile</h5>
      {htmlContent()}
    </>
  );
};

ProfileContent.propTypes = {
  candidateProfile: PropTypes.any,
};
