import PropTypes from "prop-types";

export const JobDetails = ({ details }): JSX.Element => {
  const renderValue = (label, value) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: value
            .toString()
            .replace(/(<? *script)/gi, "illegalscript")
            .replace("true", "YES"),
        }}
      ></div>
    );
  };

  return (
    <div className='border-neutral-500 border p-6 pt-8 rounded-lg max-w-xl'>
      {details.map(({ label, value }, index) => (
        <div key={index} className='grid grid-cols-2 text-neutral-1-500 py-2'>
          <div className='font-bold'>{label}</div>
          {renderValue(label, value)}
        </div>
      ))}
    </div>
  );
};

JobDetails.propTypes = {
  details: PropTypes.array,
};
