import clsx from "clsx";
import SVG from "react-inlinesvg";

import DisabledDownloadIconURL from "@assets/icons/download-disabled.svg";
import DownloadIconURL from "@assets/icons/download.svg";

import { ButtonType } from "./types";

interface DownloadButtonprops {
  disabled?: boolean;
  fullWidth?: boolean;
  label: string;
  size?: ButtonSize;
  type?: ButtonType;
  onClick?: () => void;
}

type ButtonSize = "SMALL" | "MEDIUM" | "LARGE";

const SIZE_MAPS: Record<ButtonSize, string> = {
  SMALL: "btn-sm",
  MEDIUM: "btn-md",
  LARGE: "btn-lg",
};

export const DownloadButton = ({
  disabled = false,
  fullWidth = false,
  label,
  size = "MEDIUM",
  type = "button",
  onClick = () => undefined,
}: DownloadButtonprops): JSX.Element => {
  const iconURL = (disabled): string =>
    disabled ? DisabledDownloadIconURL : DownloadIconURL;
  return (
    <button
      className={clsx(
        "btn-secondary",
        "outline-none",
        SIZE_MAPS[size],
        fullWidth && "w-full"
      )}
      disabled={disabled}
      type={type}
      onClick={onClick}
    >
      <span className='flex items-center gap-1'>
        <SVG src={iconURL(disabled)} />
        <span>{label}</span>
      </span>
    </button>
  );
};
