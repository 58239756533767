import { tenantPath } from "helpers/tenant_path";
import { JOB_OPENING_CANDIDATE_PATH } from "packs/client_app/config";
import { getHeaders } from "packs/client_app/config/request_headers";

export const requestChangeStage = (
  jobOpeningCandidateId,
  stage,
  startDate,
  endDate,
  rejectReason = null,
  rejectDetail = null,
  salary = null
) => {
  return fetch(tenantPath(JOB_OPENING_CANDIDATE_PATH(jobOpeningCandidateId)), {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify({
      stage: jobOpeningCandidateStageMapping(stage),
      end_date: endDate,
      start_date: startDate,
      reject_reason: rejectReason,
      reject_detail: rejectDetail,
      salary: salary,
    }),
  });
};

const jobOpeningCandidateStageMapping = (stage: string) => {
  const stages = {
    Responded: "responded",
    Shortlist: "shortlisted",
    Offer: "placed",
    Rejected: "rejected_by_client",
    "Dropped Out": "dropped_out",
  };

  return stages[`${stage}`];
};
