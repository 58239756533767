import i18next from "i18next";

import { ns1 } from "./en/ns1";

export const defaultNS = "ns1";

export const resources = {
  en: {
    ns1,
  },
};

i18next.init({
  lng: "en", // if you're using a language detector, do not define the lng option
  keySeparator: ".",
  resources,
  defaultNS,
});
