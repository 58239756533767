import clsx from "clsx";
import SVG from "react-inlinesvg";

import LoadingCircle from "@assets/icons/loading-circle.svg";

import { ButtonType } from "./types";

interface PrimaryButtonProps {
  loading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  label: string;
  size?: ButtonSize;
  type?: ButtonType;
  onClick?: () => void;
}

type ButtonSize = "SMALL" | "MEDIUM" | "LARGE";

const SIZE_MAPS: Record<ButtonSize, string> = {
  SMALL: "btn-sm",
  MEDIUM: "btn-md",
  LARGE: "btn-lg",
};

export const PrimaryButton = ({
  disabled,
  fullWidth,
  label,
  loading = false,
  size = "MEDIUM",
  type = "button",
  onClick = () => undefined,
}: PrimaryButtonProps): JSX.Element => {
  return (
    <button
      className={clsx("btn-primary", SIZE_MAPS[size], fullWidth && "w-full")}
      disabled={disabled || loading}
      onClick={onClick}
      type={type}
    >
      {loading && (
        <SVG className='mr-2 -ml-2 h-5 animate-spin' src={LoadingCircle} />
      )}
      {label}
    </button>
  );
};
