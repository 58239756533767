import { tenantPath } from "helpers/tenant_path";
import i18next from "i18next";
import "@/i18n/config";
import { EDIT_COMPANY_PATH } from "packs/client_app/config";
import PropTypes from "prop-types";
import { useState, ReactElement } from "react";
import Avatar from "react-avatar";
import Flatpickr from "react-flatpickr";
import SVG from "react-inlinesvg";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";

import Cross from "@assets/icons/cross.svg";

import { requestChangeStage } from "./services/StageService";
import { close } from "./store/OfferJobOpeningCandidateModalReducer";
import type { RootState } from "./store/store";

import { update as updateBoard } from "@/client_app/components/jobOpeningDetails/store/BoardReducer";
import { flash } from "@/ui/Flash";

Modal.setAppElement("body");

declare const window: any;

export const OfferDetailModal = ({
  offer_details,
  isFinalizedSetup,
}): JSX.Element => {
  const customStyles = {
    overlay: {
      zIndex: 9999,
      opacity: 1,
    },
    content: {
      background: "none",
      border: "none",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      padding: "0",
    },
  };

  const dateFormat = "d/m/Y";
  const dispatch = useDispatch();
  const isOpen = useSelector((state: RootState) => state.offerModal.isOpen);

  const maxDate = offer_details.find(
    (element) => element.label == "END DATE"
  ).value;
  const minDate = offer_details.find(
    (element) => element.label == "START DATE"
  ).value;

  const isPermanent =
    offer_details.find((element) => element.label == "EMPLOYMENT TYPE").value ==
    "Permanent";

  const [endDate, setEndDate] = useState(
    offer_details.find((element) => element.label == "END DATE").value
  );
  const [startDate, setStartDate] = useState(
    offer_details.find((element) => element.label == "START DATE").value
  );

  const [salary, setSalary] = useState(
    isPermanent
      ? offer_details.find((element) => element.label == "RATE").value
      : null
  );

  const [salary2, setSalary2] = useState("");

  const jobOpeningCandidateId = useSelector(
    (state: RootState) => state.offerModal.jobOpeningCandidateId
  );
  const candidateName = useSelector(
    (state: RootState) => state.offerModal.candidateName
  );
  const stage = useSelector((state: RootState) => state.offerModal.stage);
  const currentStage = useSelector(
    (state: RootState) => state.offerModal.currentStage
  );

  const onCancel = () => {
    setStartDate(minDate);
    setEndDate(maxDate);

    dispatch(
      close({
        isOpen: false,
        nextStage: currentStage,
        currentStage: currentStage,
      })
    );
  };

  const onConfirm = async () => {
    const response = await requestChangeStage(
      jobOpeningCandidateId,
      stage,
      startDate.toString(),
      endDate?.toString(),
      null,
      null,
      salary
    );

    const data = await response.json();

    if (response.ok) {
      const columns = data.columns;

      flash.notice("Stage changed!");
      dispatch(close({ isOpen: false }));
      dispatch(
        updateBoard({
          columns: {
            Responded: columns.Responded,
            Shortlist: columns.Shortlist,
            Offer: columns.Offer,
            Rejected: columns.Rejected,
            "Dropped Out": columns["Dropped Out"],
          },
        })
      );
    } else {
      dispatch(close({ isOpen: false }));
      flash.notice(data.error);
    }

    setStartDate(minDate);
    setEndDate(maxDate);
  };

  const remoteWorkingLocation = useSelector(
    (state: RootState) => state.offerModal.remoteWorkingLocation
  );

  if (remoteWorkingLocation) {
    const temporaryOfferDetails = [];

    offer_details.forEach((detail) => {
      temporaryOfferDetails.push(detail);
      if (detail.label == "OFFICE LOCATION") {
        temporaryOfferDetails.push({
          label: i18next.t("clientApp.weployeeLocation", {
            terminologyEmployee: window.terminologyEmployee,
          }),
          value: remoteWorkingLocation,
        });
      }
    });
    offer_details = temporaryOfferDetails;
  }

  if (!isFinalizedSetup)
    return (
      <Modal isOpen={isOpen} contentLabel='Example Modal' style={customStyles}>
        <div className='animated fadeIn fixed inset-0 overflow-y-auto flex items-center justify-center'>
          <div className='border-neutral-500 bg-legacyColor-secondary-50 border p-6 pt-8 rounded-lg max-w-xl w-full text-center relative'>
            <button
              className='font-bold absolute top-1 left-2'
              onClick={onCancel}
            >
              <SVG
                src={Cross}
                className='icon fill-current mr-4 cursor-pointer'
              />
            </button>
            <div className='text-center text-legacyColor-neutral-1-500 font-bold mb-4 text-3xl'>
              Account Set Up Required
            </div>
            <div className='text-center mb-4'>
              <p className='text-xl'>
                To proceed further, please ensure the account
              </p>
              <p className='text-xl'>has successfully been set up</p>
            </div>
            <a
              href={tenantPath(EDIT_COMPANY_PATH)}
              className='btn btn-sm btn-primary m-auto text-lg'
            >
              Finalise account set up
            </a>
          </div>
        </div>
      </Modal>
    );

  const RenderOfferDetail = ({ value, hashKey, index }): ReactElement => {
    return (
      <div
        key={index}
        className='grid grid-cols-2 text-neutral-1-500 py-2 text-left'
      >
        <div className='font-bold text-left flex items-center'>{hashKey}</div>
        <span>{value}</span>
      </div>
    );
  };

  const RenderStartDate = (): ReactElement => {
    return (
      <div className='grid grid-cols-2 text-neutral-1-500 py-2'>
        <div className='font-bold text-left flex items-center'>START DATE</div>
        <Flatpickr
          className='form-control'
          name='startDate'
          onClose={startDateOnClose}
          defaultValue={minDate}
          value={startDate}
          options={{
            dateFormat: dateFormat,
          }}
          placeholder='Select date range'
        />
      </div>
    );
  };

  const handleChange = (event): void => {
    setSalary(event.target.value);
  };

  const RenderEndDate = (): ReactElement => {
    if (!isPermanent) {
      return (
        <div className='grid grid-cols-2 text-neutral-1-500 py-2'>
          <div className='font-bold text-left flex items-center'>END DATE</div>
          <Flatpickr
            className='form-control'
            name='endDate'
            onClose={endDateOnClose}
            defaultValue={maxDate}
            value={endDate}
            options={{
              dateFormat: dateFormat,
            }}
            placeholder='Select date range'
          />
        </div>
      );
    }
  };

  const startDateOnClose = (_, dateSelected: string) => {
    const submitButton = document.querySelector("[id='offer-submit']");

    if (!dateSelected) {
      submitButton.setAttribute("disabled", "true");
    } else if (endDate) {
      submitButton.removeAttribute("disabled");
    }

    setStartDate(dateSelected);
  };

  const endDateOnClose = (_, dateSelected: string) => {
    const submitButton = document.querySelector("[id='offer-submit']");

    if (!dateSelected) {
      submitButton.setAttribute("disabled", "true");
    } else if (startDate) {
      submitButton.removeAttribute("disabled");
    }

    setEndDate(dateSelected);
  };

  return (
    <Modal isOpen={isOpen} contentLabel='Example Modal' style={customStyles}>
      <div className='animated fadeIn fixed inset-0 overflow-y-auto flex items-center justify-center'>
        <div className='border-neutral-500 bg-legacyColor-secondary-50 border p-6 pt-8 rounded-lg max-w-xl w-full text-center'>
          <div className='text-center'>
            <Avatar
              className='bg-primary-50 text-primary-900 rounded-full w-16 h-16'
              name={candidateName}
            />
          </div>
          <h2>Send Offer</h2>
          <p className='mt-4 mb-4'>
            {i18next.t("clientApp.readyToHireYourWeployee", {
              terminologyEmployee: window.terminologyEmployee,
            })}
          </p>
          {offer_details.map(({ label, value }, index) => {
            if (label === "START DATE") {
              return <RenderStartDate key={index} />;
            } else if (label === "END DATE") {
              return <RenderEndDate key={index} />;
            } else if (label === "RATE" && isPermanent) {
              return (
                <div
                  className='grid grid-cols-2 text-neutral-1-500 py-2'
                  key={index}
                >
                  <div className='font-bold text-left flex items-center'>
                    SALARY
                  </div>
                  <input
                    className='form-control'
                    type='text'
                    defaultValue={salary}
                    required={true}
                    onChange={handleChange}
                  />
                </div>
              );
            } else {
              return (
                <RenderOfferDetail
                  key={index}
                  value={value}
                  hashKey={label}
                  index={index}
                />
              );
            }
          })}

          <div className='flex justify-end items-center flex-wrap mt-6'>
            <button className='btn btn-sm m-auto font-bold' onClick={onCancel}>
              Cancel
            </button>
            <button
              id='offer-submit'
              className='btn btn-sm btn-primary m-auto'
              onClick={onConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

OfferDetailModal.propTypes = {
  offer_details: PropTypes.array,
};
